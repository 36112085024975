import logo from './logo.svg';
import './App.css';
import './style.sass';

import React from 'react';
import { useState} from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery';

import { BrowserRouter, Routes, Route, useNavigate, useLocation, Outlet } from "react-router-dom";

import { getFirestore, getDoc, onSnapshot, doc } from "firebase/firestore";

import { getAuth, browserSessionPersistence, setPersistence, signInWithCustomToken, applyActionCode, sendEmailVerification, signInWithCredential, inMemoryPersistence } from 'firebase/auth';
import { setUser, signOut, refreshData, setToken, setRanks, setAlerts, setAffiliations, setSyllabi, setAgencies, setRoles, setCourses, setConfig, setUnitRoles } from './features/data'
import { setIsMobile, setPageTitle, setShowProfile, setSystem, setTenantInfo } from './features/app'

import { Alert, Container, Card, Box, LinearProgress, Backdrop, CircularProgress, Snackbar, Button, AlertTitle, Dialog, DialogContent, DialogTitle } from '@mui/material';
import Stack from '@mui/material/Stack';

import PageTitle from './components/PageTitle';
//import {getRemoteConfig, fetchAndActivate, getAll} from 'firebase/remote-config';

//PAGES
import Profile from './training/Profile';		
import Register from './training/Register';
import Login from './training/Login';
import Donation from './admin/Donation';

//UTILITIES
import jax from './helper/jax';
import cookies from 'js-cookie';
import theme from './theme';

import fb_app from './helper/firebase'
// import systems from './systems';
import SafetyReport from './training/FileSafetyReport';
import { hasAnyRole, hasRole, roles } from './features/access';

import system from './systems';
import Home from './training/Home';
import Guard from './components/GuardedRoute';
import { ArrowBack, Lock, Update } from '@mui/icons-material';

import moment from 'moment';



//const test = require('./test.js');

const auth = getAuth(fb_app);
const db = getFirestore(fb_app);
const host = window.location.hostname.toLowerCase();
const subdomain = host.split('.')[0];

var cookie_domain = window.location.hostname.replace(/^[^\.]+/,'');

//Customize the moment locale / time range descriptions
moment.updateLocale('en', {
    relativeTime : {
        future: "in %s",
        past:   "%s ago",
        s  : '%d seconds',
        ss : '%d seconds',
        m:  "%d minute",
        mm: "%d minutes",
        h:  "%d hour",
        hh: "%d hours",
        d:  "a day",
        dd: "%d days",
        w:  "a week",
        ww: "%d weeks",
        M:  "a month",
        MM: "%d months",
        y:  "a year",
        yy: "%d years"
    }
});

export default function App(props) {
	
	const user = useSelector((state) => state.data.user);	
	const refresh_timestamp = useSelector((state) => state.data.refresh_timestamp);
	const show_profile = useSelector((state) => state.app.show_profile);
	const [loading, setLoading] = useState([]);
	const [responseError, setResponseError] = useState(null);
	const [authUser, setAuthUser] = useState(null);
	const [offline, setOffline] = useState(false);
	const [nextUpdate, setNextUpdate] = useState(null);
	const [updateText, setUpdateText] = useState(null);
	const [hasNewVersion, setHasNewVersion] = useState(false);
	const [hideOfflineMsg, setHideOfflineMsg] = useState(false);
	
	
	const dispatch = useDispatch();

	const is_mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

	const [authState, setAuthState] = useState(null);

	const updateRef = useRef({ts: null, timeout: null});


	//Handle resize events
	useEffect(function() {		
		dispatch(setPageTitle("Valkyrie Training"));
		dispatch(setIsMobile(is_mobile));
		// dispatch(setSystem(subdomain));	
		window.addEventListener('resize', function() {
			// var mq = theme.breakpoints.down('sm');
			this.setTimeout(()=>{
				var mobile = window.innerWidth < theme.breakpoints.values.sm;
				dispatch(setIsMobile(mobile));
			}, 50)
		});
		
	}, []);	

	useEffect(function() {
		
		//Check to see if this is a link to verify an email address
		let params = new URL(document.location).searchParams;
		let mode = params.get("mode");
		if (mode == 'verifyEmail') {
			var continueTo = null;
			if (params.get('continueUrl')){
				try {
					continueTo = new URL(params.get('continueUrl'));
					continueTo.searchParams.append('v', '1');
				} catch {}
			}

			applyActionCode(auth, params.get('oobCode')).then((resp)=>{
				sign_out();
				if (continueTo) {
					window.location.replace(continueTo);
				}
			}).catch((error)=>{
				return;
			});
			return;
		}
		
		if(authUser) {
			authUser.getIdToken().then((token) =>{
				signIn(token, authUser.providerData[0]?.providerId);
			}).catch(function(error) {
				console.error(error);
			});
		}
	}, [authUser]);

	function sign_out() {
		getAuth().signOut().then(()=>{
			setAuthState(false);
			dispatch(signOut());
			
		});	
	}
	
	const signIn = async function(id_token, token_provider) {
		if (id_token && token_provider) {
			try {
				var r = await jax.post('/app/signin', {id_token: id_token, id_provider: token_provider});
				cookies.set('csrf', r.csrf);
				auth.tenantId = r.user.tenant_id;
				//Custom token contains user claims (roles).  Go ahead and save that cookie
				var cred = await signInWithCustomToken(auth, r.user.token);
				var token = await cred.user.getIdToken();
				//cookies.set('id_token', token, { expires: 7, domain: cookie_domain });
				
				//This *should* be called on every refresh
				auth.onIdTokenChanged(async function(user) {
					if (user) {
						var token = await user.getIdToken();
						dispatch(setToken({id_token: token, provider: token_provider}));
						//console.log(`New token issued: ${token}`);
						//cookies.set('id_token', token, { expires: 7, domain: cookie_domain });
					} else {
						//cookies.remove('id_token', { domain: cookie_domain });
					}
				});


				//Expand unit roles for easy checking.  They are summarized in the payload
				var unit_roles = Object.keys(r.user.unit_summary).reduce((acc, key)=>{
					if (key.indexOf('-') == -1) {
						acc[key] = r.user.unit_summary[key];
					} else {
						var [start, end] = key.split('-');
						for (var i=start; i<=end; i++) {
							acc[i] = r.user.unit_summary[key];
						}
					}
					return acc;
				}, {});

				r.user.units =  unit_roles;

				dispatch(setUser(r.user));
				dispatch(setCourses(r.courses));
				dispatch(setRanks(r.ranks));
				dispatch(setAgencies(r.agencies));
				dispatch(setAffiliations(r.affiliations));
				dispatch(setRoles(r.roles));
				dispatch(setSyllabi(r.syllabi));
				dispatch(setConfig(r.config));
				dispatch(setUnitRoles(r.unit_roles));
				dispatch(setTenantInfo(r.tenant));
				setAuthState(true);
			

				fetchAlerts();
			} catch(err) {

				//Token has expired or is not valid
				if (err.code == 403) {
					//Bad ticket
					cookies.remove('id_token', { domain: cookie_domain });
					window.dispatchEvent(new Event('bad_ticket'));
					return;
				} else {
					console.error('Sign in error');
					console.error(err);
				}
				
			}
		}
	}

	const fetchAlerts = async () => {
		const results = await jax.get('/app/profile/alerts');
		dispatch(setAlerts(results));
	}

	useEffect(()=>{
		
		//We use a cookie in order to share the token with separate modules (subdomains).  Firebase has no built in mechanism for this.
		
		setPersistence(auth, browserSessionPersistence).then(() => {
			auth.onAuthStateChanged(function(fb_user) {
				if (fb_user) {
					setAuthUser(fb_user);					
				} else {
					// var token = cookies.get('id_token');
					// if (token) {
					// 	//remove the old token to prevent infinite loop
					// 	//cookies.remove('id_token', { domain: cookie_domain });
					// 	signIn(token, 'email');
					// } else {
						setAuthUser(null);
						setAuthState(false);
					//}
				}
			})
		});
		

		window.addEventListener('bad_ticket', function() {
			// cookies.remove('id_token', { domain: cookie_domain });
			// debugger;
			getAuth().signOut().then(()=>{
				dispatch(signOut());

				window.history.replaceState(null, 'New Page Title', '/')
			});
		});

		window.addEventListener('loading_start', function(e) {		
			var ld = [...loading, e.timeStamp];
			setLoading(ld);
		});

		window.addEventListener('load_alerts', function(e) {		
			fetchAlerts();
		});
	
		window.addEventListener('loading_complete', function(e) {
			var ld = Array.from(loading);
			ld.pop();
			setLoading(ld);
		});

		window.addEventListener('unexpected_error', function(e) {
			setResponseError("An unexpected error occurred. Please try again.");
		});

		window.addEventListener('unauthorized', function(e) {
			setResponseError("You are not authorized to perform this action.");
		});

		onSnapshot(doc(db, 'system', 'config'), (doc)=>{
			var data = doc.data(); 

			//if nextupdate is a boolean, it is a directive of whether the system is offline
			if (data.nextupdate === false || data.nextupdate === true) {
				setNextUpdate(data.nextupdate);
				return;
			}

			//If an update is upcoming
			if (moment().isBefore(moment(data.nextupdate.seconds*1000))) {
				updateRef.current.ts = data.nextupdate.seconds*1000;
				setNextUpdate(moment(data.nextupdate.seconds*1000));
			} else { //if the update has passed, the system will be offline
				updateRef.current.ts = null;
				setNextUpdate(moment(data.nextupdate.seconds*1000));
			}
		});
		
	}, []);	

	//TO SCHEDULE OR TAKE THE SYSTEM OFFLINE:
	// - Set the nextupdate field in the system/config document
	// - Future date: a timer will be displayed counting down to the update
	// - Past date: the system will be offline
	// - Boolean: TRUE = offline, FALSE = online
	useEffect(function() {
		//Clear the interval any time nextUpdate is modified
		if (updateRef.current.timeout) {
			clearInterval(updateRef.current.timeout);
		}

		//If the system is offline, set the offline flag
		if (nextUpdate === false) {
			setOffline(false);
			setUpdateText(null);
			//If the system was previously offline, an update occured and the page should be refreshed
			setHasNewVersion(updateRef.current.offline === true);
			return;
		} else if (nextUpdate === true) {
			setOffline(true);
			setUpdateText(null);
			updateRef.current.offline = true;
			return;
		}

		if (!nextUpdate && updateRef.current.ts) {
			setHasNewVersion(true);
		} else if (nextUpdate) {
			setHasNewVersion(false);
			updateRef.current.timeout = setInterval(()=>{
				console.log('tick');
				var mmt = moment(nextUpdate.seconds*1000).fromNow();
				if (moment().isBefore(moment(nextUpdate))) {
					setOffline(false)
					setUpdateText(moment(nextUpdate).fromNow());	
				} else {
					//If the update date has passed and is nota boolean, the system is now offline
					setNextUpdate(null);
					setUpdateText(null);
					setOffline(true);
					updateRef.current.ts = null;
					updateRef.current.offline = true;

					//Clear the interval
					clearInterval(updateRef.current.timeout);
				}
			}, 1000);
		}
		
		//return () => { debugger; clearInterval(updateRef.current.timeout);};
	}, [nextUpdate]);


	useEffect(function() {
		if (updateRef.current.offline === false) {
			setHideOfflineMsg(false);
		}
	}, [updateRef]);

	const RosterRoutes = function() {
		return <></>;
	}
	const DonorRoutes = function() {
		return <></>;
	}

	return (<>
		
		<div className={`App full-height SYSTEM-${process.env.REACT_APP_SYSTEM}`}>
			
			{/* <Backdrop
				className="loading-bg"
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={!!loading.length || authState === null}>
				<CircularProgress color="inherit" />
			</Backdrop> */}
			
			<Snackbar open={!!responseError} autoHideDuration={6000} onClose={()=>setResponseError(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
				<Alert severity="error" sx={{ width: '100%' }} variant="filled" >
					{responseError}
				</Alert>
			</Snackbar>
			
			{authState !== null && <BrowserRouter >
				<Container className="full-height" sx={{display: 'flex', flexDirection: 'column', p:{ xs: "1rem 1rem 4rem", md: 5}}}>
					{!!user && <PageTitle loading={loading} />}
					<Stack className="full-height">
						<Routes>
							<Route path='/register/*' element={<Register/>}/>
							<Route path='/safety/:course_code?' element={<SafetyReport/>}/>
							<Route path="/donation/:code" element={<Donation/>}/>
							<Route path='/login/*' replaceState element={<Login/>}/>,
							
							{user && system.map((s,i)=>s.routes?.map((r,j)=><Route path={`${s.location}/${r.location}`} key={-j} element={<Guard unitRoles={[...(r.unitRoles || s.unitRoles || [])]} requireEmailVerification={s.requireEmailVerification}>{r.element}</Guard>}></Route>))}
							{user && system.map((s,i)=>{
								return <Route path={s.location} key={i} element={<Guard unitRoles={s.unitRoles} requireEmailVerification={s.requireEmailVerification}>{s.element}</Guard>}></Route>;
							})}
							
							{user ? <>
								<Route path='/profile/:uid' element={<Profile/>}/>
								<Route path='/profile' element={<Profile/>}/>
								<Route path="*" element={<h3>This page was not found or you do not have access to it.</h3>} />
							</> : 
								<Route path="*" element={<Login authState={authState}/>} />
							} 
						</Routes>
						
					</Stack>
				</Container>
			</BrowserRouter>}

			
		</div>
		
			<Dialog open={(offline && !hideOfflineMsg) || hasNewVersion} fullWidth>
				<DialogTitle>
					{offline ? "Currently Under Maintenance" : "New Version Available"}
				</DialogTitle>
				<DialogContent>
					{offline && <Stack spacing={1} alignItems="center">
						<Box>This system is currently offline for maintenance.  Please check back in a few minutes.</Box>
						{hasRole(user, roles.ADMIN) && <Box><Button onClick={()=>setHideOfflineMsg(true)} startIcon={<Lock/>}>Hide Message</Button></Box>}
					</Stack>}
					{hasNewVersion && <Stack spacing={1} alignItems="center">
						<Box>A new version is available and this page needs to be reloaded.  Please click below to refresh.</Box>
						<Box>
							<Button onClick={()=>window.location.reload()} variant="contained">Refresh</Button>
						</Box>
					</Stack>}
				</DialogContent>
			</Dialog>

		<Snackbar open={!!updateText} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} message={<Stack direction="row" spacing={1} alignItems="center">
			<Box lineHeight={0}><Update/></Box>
			<Box>This system will be offline for maintenance {updateText}</Box>
		</Stack>} />
		
	</>);
}
